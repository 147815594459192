import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TopHeading } from '../components/heading';
import PostPreview from '../components/postpreview';

class TagsEntryPageTemplate extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const { tag, langKey } = this.props.pageContext;

        const { edges, totalCount } = data.allMarkdownRemark;
        const tagHeader =
            langKey === 'de'
                ? `${totalCount} ${
                      totalCount === 1 ? 'Beitrag' : 'Beiträge'
                  } unter dem Tag "${tag}"`
                : `${totalCount} post${
                      totalCount === 1 ? '' : 's'
                  } tagged with "${tag}"`;

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                lang={langKey}
            >
                <SEO
                    lang={langKey}
                    title={'Tag: ' + tag}
                    description={data.site.siteMetadata.description}
                />
                <TopHeading>{tagHeader}</TopHeading>
                <Link to={langKey === 'de' ? '/de/tags' : '/tags'}>
                    {langKey === 'de' ? 'Alle Tags' : 'All tags'}
                </Link>
                <ul>
                    {edges.map(({ node }) => {
                        const { slug } = node.fields;

                        return <PostPreview key={slug} post={node} />;
                    })}
                </ul>
            </Layout>
        );
    }
}

export default TagsEntryPageTemplate;

export const pageQuery = graphql`
    query($tag: String, $langKey: String!) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
                frontmatter: { tags: { in: [$tag] } }
                fields: { langKey: { eq: $langKey } }
            }
        ) {
            totalCount
            edges {
                node {
                    excerpt
                    fields {
                        slug
                        langKey
                    }
                    timeToRead
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`;
